export const investmentsActiveColumns = [
	{
		header: 'Perfil',
		accessor: 'profileInvestor',
	},
	{
		header: 'Inversión',
		accessor: 'incomeRecivedDate',
	},
	{
		header: 'Importe',
		accessor: 'amount',
	},
	{
		header: 'Proyecto',
		accessor: 'project',
	},
	{
		header: 'Tipo',
		accessor: 'investmentType',
	},
	{
		header: 'Estado',
		accessor: 'status',
	},
	{
		header: 'Linea de Tiempo',
		accessor: 'progressProject',
		disabledSort: true,
	},
	{
		header: 'Documentos',
		accessor: 'actions',
		cellAlign: 'right',
		headerAlign: 'right',
	},
]
export const pdlsActiveColumns = [
	{
		header: 'Perfil',
		accessor: 'profileInvestor',
	},
	{
		header: 'Inversión',
		accessor: 'incomeRecivedDate',
	},
	{
		header: 'Importe',
		accessor: 'amount',
	},
	{
		header: 'Proyecto',
		accessor: 'project',
	},
	{
		header: 'Tipo',
		accessor: 'investmentType',
	},
	{
		header: 'Estado',
		accessor: 'status',
	},
]
export const investmentsFinishColumns = [
	{
		header: 'Perfil',
		accessor: 'profileInvestor',
	},
	{
		header: 'Importe',
		accessor: 'amount',
	},
	{
		header: 'Proyecto',
		accessor: 'project',
	},
	{
		header: 'Tipo',
		accessor: 'investmentType',
	},
	{
		header: 'Inversión',
		accessor: 'incomeRecivedDate',
	},
	{
		header: 'Inicio Proyecto',
		accessor: 'startDateUpdated',
	},
	{
		header: 'Fecha Liquidación',
		accessor: 'liquidatedDate',
	},
	{
		header: 'Días Inversión',
		accessor: 'date',
	},
	{
		header: 'Mi beneficio AI',
		accessor: 'benefit',
	},
	{
		header: 'Mi TIR',
		accessor: 'TIR',
	},
	{
		header: 'Acción con fondos',
		accessor: 'liquidation',
	},
	{
		header: 'Documentos',
		accessor: 'actions',
		cellAlign: 'right',
		headerAlign: 'right',
	},
]
export const pdlsFinishColumns = [
	{
		header: 'Perfil',
		accessor: 'profileInvestor',
	},
	{
		header: 'Importe',
		accessor: 'amount',
	},
	{
		header: 'Proyecto',
		accessor: 'project',
	},
	{
		header: 'Tipo',
		accessor: 'investmentType',
	},
	{
		header: 'Inversión',
		accessor: 'incomeRecivedDate',
	},
	{
		header: 'Inicio Proyecto',
		accessor: 'startDateUpdated',
	},
	{
		header: 'Fecha Liquidación',
		accessor: 'liquidatedDate',
	},
	{
		header: 'Días Inversión',
		accessor: 'date',
	},
	{
		header: 'Mi beneficio AI',
		accessor: 'benefit',
	},
	{
		header: 'Retenciones',
		accessor: 'retention',
	},
	{
		header: 'Acción con fondos',
		accessor: 'liquidation',
	},
]
