import { differenceInCalendarDays, format } from 'date-fns'
import { replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { changeIncomeReciveDate } from 'shared/helpers/functions-helpers'

const liquidationNames = {
	reinvest: 'Reinvertir',
	pay: 'Pagar',
	'benefits-only': 'Pagar solo beneficios',
	'finally-paid': 'Finalmente pagados',
}

const liquidateExcelMapper = (investments) => {
	return investments?.map((item) => {
		const participation =
			(replaceStringToNum(item?.amount || '0.00 €') / replaceStringToNum(item?.projectCost || '0.00 €')) * 100

		const days =
			item.incomeRecivedDate && item?.depositDate
				? differenceInCalendarDays(
						new Date(item?.depositDate),
						new Date(changeIncomeReciveDate(item.incomeRecivedDate, item.startDateUpdated)),
				  ) - 1
				: 'No Recibido'

		return {
			csvProfileInclude: item?.csvProfileInclude ? 'Incluido' : 'No Incluido',
			businessName: item?.businessName || 'Sin nombre',
			bankAccountNumber: item?.bankAccountNumber || 'Sin iban',
			incomeRecivedDate: item.incomeRecivedDate ? format(new Date(item.incomeRecivedDate), 'dd-MM-yyyy') : 'Sin fecha',
			depositDate: item?.depositDate ? format(new Date(item.depositDate), 'dd-MM-yyyy') : 'Sin fecha',
			amount: replaceStringToNum(item?.amount || '0.00 €'),
			liquidation: liquidationNames[item?.liquidation],
			participation: Number(participation.toFixed(2)),
			retention: item?.retention || 'Sin retención',
			benefit: Number(item?.benefit || 0),
			retentionAmount: replaceStringToNum(item?.retentionAmount || '0.00 €'),
			investorTIR: Number(item?.investorTIR || 0),
			toPay: replaceStringToNum(item?.toPay || '0.00 €'),
			days,
			benefitForIRR: replaceStringToNum(item?.benefitForIRR || '0.00 €'),
			taxBase: Number(item?.taxBase || 0),
			feeRate: item?.feeRate || 0,
			fee: Number(item?.fee || 0),
		}
	})
}

export default liquidateExcelMapper
