const liquidateExcelColumns = [
	{ header: 'Incluir', accessor: 'csvProfileInclude' },
	{ header: 'Perfil Inversor', accessor: 'businessName' },
	{ header: 'IBAN', accessor: 'bankAccountNumber' },
	{ header: 'Fecha de inversión', accessor: 'incomeRecivedDate' },
	{ header: 'Fecha de devolución', accessor: 'depositDate' },
	{ header: 'Importe de inversión', accessor: 'amount', key: 'number' },
	{ header: 'Liquidación', accessor: 'liquidation' },
	{ header: 'Participación', accessor: 'participation', key: 'number' },
	{ header: 'Retención', accessor: 'retention' },
	{ header: 'Beneficio', accessor: 'benefit', key: 'number' },
	{ header: 'Importe de retención', accessor: 'retentionAmount', key: 'number' },
	{ header: 'TIR Inversor', accessor: 'investorTIR' },
	{ header: 'A pagar', accessor: 'toPay', key: 'number' },
	{ header: 'Días', accessor: 'days' },
	{ header: 'Beneficio para TIR =12%', accessor: 'benefitForIRR', key: 'number' },
	{ header: 'Base imponible para Success Fee', accessor: 'taxBase', key: 'number' },
	{ header: 'Fee tasa (50%)', accessor: 'feeRate' },
	{ header: 'Fee', accessor: 'fee', key: 'number' },
]

export default liquidateExcelColumns
