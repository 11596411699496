import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import styles from '../styles/filterInvestmentStyles'

const useStyles = makeStyles(styles)

const FilterTypes = ({ filter, setFilter, pdls }) => {
	const classes = useStyles()
	const options = pdls.length > 0 ? ['PDLs', 'Coinversiones'] : ['Coinversiones']

	return (
		<FormControl fullWidth>
			<InputLabel id='log-type-select'>{!filter ? 'Buscar tipos' : `Tipos de Inversiones`}</InputLabel>
			<Select
				MenuProps={{
					className: classes.selectMenu,
				}}
				labelId='log-type-select'
				id='log-type'
				label='Tipo *'
				value={filter}
				onChange={(e) => {
					setFilter(e.target.value)
				}}
			>
				{options.map((entityType, index) => (
					<MenuItem
						value={entityType}
						key={index}
						classes={{
							root: classes.selectMenuItem,
							selected: classes.selectMenuItemSelected,
						}}
					>
						{entityType}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default FilterTypes
